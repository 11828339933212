import React, { useEffect, useState } from 'react'
import styles from '../PageFourFolder/PageFour.module.css'
// import img1 from '../Media//cleopatra hospital.webp'
// import img2 from '../Media/el borg lab.webp'
// import img3 from '../Media/techno lab.webp'
// import img4 from '../Media/alfa lab.webp'
import AOS from "aos";
import 'aos/dist/aos.css'
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Navigation, Pagination, Autoplay , EffectCreative } from 'swiper/modules';
import 'animate.css';
import $ from 'jquery'
import axios from 'axios'

  
export default function PageFour() {

  const [imgApi, setImgApi] = useState([])
  async function getImgApi(){
   let {data} = await axios.get(`https://dms.up.railway.app/api/img/`);
   setImgApi(data)   
  }

    useEffect(() => {

      getImgApi()
        AOS.init({duration: 2000})

        let pageFour = $('.technoScan').offset().top;
        $(window).scroll(function (){
          let wScroll = $(window).scrollTop();

          if(wScroll > pageFour -50) {
            $('#successPartner').addClass('mainColor animate__animated animate__fadeInLeft')
          }
          else {
            $('#successPartner').removeClass('mainColor animate__animated animate__fadeInLeft')
          }
        })
      }, [])
    



      
  return (
   <>
   
   <section id='successPartner' className={`${styles.BGshadow} `}>



    <div className="container" id='successPartners'>
        <div className="row justify-content-center align-items-center">
          <div className="col-md-6 my-5">
            <div className='my-5'>
            <div className='text-center fs-1 fw-bolder position-relative technoScan'> Success Partners
              {/* <div className={`${styles.middleTric} position-absolute top-50 start-50 translate-middle `}> S </div> */}
            </div>
            </div>
                {/* <p className='text-black text-center fs-5 mb-4'>ALFA LAB</p>
                <p className='text-black text-center fs-5 my-4 '>CLEOPATRA HOSPITAL</p>
                <p className='text-black text-center fs-5 my-4'>TECHNO SCAN</p>
                <p className='text-black text-center fs-5 mt-4'>AL BORG LAB</p> */}
          </div>


            <div className="col-md-6">

             <Swiper 
     autoplay={{
     delay: 2000,
    disableOnInteraction: false,}}
        grabCursor={true}
        effect={'creative'}
        creativeEffect={{
          prev: {
            shadow: true,
            translate: ['-125%', 0, -800],
            rotate: [0, 0, -90],
          },
          next: {
            shadow: true,
            translate: ['125%', 0, -800],
            rotate: [0, 0, 90],
          },
        }}
        modules={[EffectCreative,Autoplay, Pagination, Navigation]}
        className="mySwiper5 text-center my-1"
      >
           {imgApi.map((imgApi , i)=> <SwiperSlide  key={i}>  
            <img src={imgApi.coImg} alt="dms" className={`${styles.sliderImgEditSize}`}/>                
            </SwiperSlide>)}
        
      {/* <SwiperSlide> <img src={img4} alt="alfa lab" className={`${styles.sliderImgEditSize}`}/></SwiperSlide>
      <SwiperSlide> <img src={img2} alt="el borg lab" className={`${styles.sliderImgEditSize}`}/></SwiperSlide>
      <SwiperSlide> <img src={img3} alt="techno lab" className={`${styles.sliderImgEditSize}`}/></SwiperSlide>
      <SwiperSlide> <img src={img1} alt="cleopatra hospital" className={`${styles.sliderImgEditSize}`}/></SwiperSlide> */}
   
             </Swiper>

            </div>

        </div>
    </div>

   </section>


   </>
  )
}
