import './App.css';
import { Route , Routes } from 'react-router-dom';
import Footer from './Components/FooterFolder/Footer';
import Nav from './Components/NavFolder/Nav';
import PageOne from './Components/PageOneFolder/PageOne';
import AboutUs from './Components/AboutUsFolder/AboutUs';
import Services from './Components/ServicesFolder/Services';
import Department from './Components/DepartmentFolder/Department';
import ContactUs from './Components/ContactUsFolder/ContactUs';
import OnlineSystem from './Components/OnlineSystemFolder/OnlineSystem';
import OurClients from './Components/OurClientsFolder/OurClients';
import ServicesDetails from './Components/ServicesFolder/ServicesDetails';
import ServicesDetails2 from './Components/ServicesFolder/ServicesDetails2';
import ServicesDetails3 from './Components/ServicesFolder/ServicesDetails3';
import DepartmentDetails1 from './Components/DepartmentFolder/DepartmentDetails1';
import DepartmentDetails2 from './Components/DepartmentFolder/DepartmentDetails2';
import DepartmentDetails3 from './Components/DepartmentFolder/DepartmentDetails3';
import DepartmentDetails4 from './Components/DepartmentFolder/DepartmentDetails4';
import ScrollToTop from './Components/ScrollTopNavigation/ScrollTop';

function App() {
  return (
    <>
  
<div className='App'>
                <ScrollToTop>
                <Nav/>
               
                    <Routes>
                              <Route path='*' element={ <PageOne/>}/>
                              <Route path='/' element={ <PageOne/>}/>
                              <Route path='home' element={ <PageOne/>}/> 
                              <Route path='aboutUs' element={ <AboutUs/>}/>
                              <Route path='services' element={ <Services/>}/>
                              <Route path='servicesDetails' element={ <ServicesDetails/>}/>
                              <Route path='servicesDetails2' element={ <ServicesDetails2/>}/>
                              <Route path='servicesDetails3' element={ <ServicesDetails3/>}/>
                              <Route path='department' element={ <Department/>}/>
                              <Route path='departmentDetails1' element={ <DepartmentDetails1/>}/>
                              <Route path='departmentDetails2' element={ <DepartmentDetails2/>}/>
                              <Route path='departmentDetails3' element={ <DepartmentDetails3/>}/>
                              <Route path='departmentDetails4' element={ <DepartmentDetails4/>}/>
                              <Route path='contactUs' element={ <ContactUs/>}/>
                              <Route path='onlineSystem' element={ <OnlineSystem/>}/>
                              <Route path='ourClients' element={ <OurClients/>}/>
                             
                    </Routes>
             
                <Footer/>
                </ScrollToTop>
</div>
       

    



</>
  );
}

export default App; 
