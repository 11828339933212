import React, { useEffect } from 'react'
import styles from '../ContactUsFolder/ContactUs.module.css'
import $ from 'jquery'
import 'animate.css';


export default function ContactUs() {

  useEffect(() => {
        
    // $('.headerAnimated').click(function(){
    //     $(this).next().slideToggle(500);
    //     $('.paragUp1').not($(this).next()).slideUp(500);    
    // })
    document.getElementById('tech').addEventListener('click' , function(){

      $('#techHide').toggle(2500)
    })

  }, [])

  return (
  <>
    <section className={`${styles.colorAnimatedBG} pt-5`}>
      <div className="container">
           <div className="row">
            <h2 className='text-center my-5 fw-bold text-white'>Contact Us
                    <div className="longline mt-1"></div>
                    <div className="shortline mt-1"></div>
                       
            </h2> 

              <div className={`${styles.Mobileresponsive} d-flex justify-content-around`}>
                  <div className=" text-white my-3 animate__animated animate__heartBeat">
                    <div>
                    <span><i class="fa-solid fa-address-card fs-4 me-1"></i></span>
                    <span className='fs-4 fw-bold'>Contact Info</span>
                    </div>
                   
                  
                    <p><span className='fw-bold'> Address : </span> 12 Abd El-Azeem Awad Allah St., Heliopolis, Cairo</p>
                    <p><span className='fw-bold'> Phone : </span> 02 26401444 – 02 26381814 </p>
                    <p><span className='fw-bold'> Fax : </span> 02 26401666 </p>
                    <p><span className='fw-bold'> Email : </span> info@dms-eg.com - Marketing@dms-eg.com </p>
                  </div>
                  <div className=" text-white my-3 animate__animated animate__heartBeat">
                  <div className='me-1'>
                    <span><i class="fa-regular fa-clock fs-4 me-1"></i></span>
                    <span className='fs-4 fw-bold'>Opening Hours</span>
                    <p>Sunday - Thursday <br /> from 8:30 to 17:00</p>
                    </div>
                   
                    
                    <div className='me-1'>
                    <span><i class="fa-solid fa-tower-broadcast fs-4 me-1"></i></span>
                    <span className='fs-4 fw-bold'>Emergency</span>
                    <p>02 26371716 <br /> Call for medical approvals</p>
                    </div>
                   
                  </div>
              </div>

              <div className="col-md-12">
                <iframe title='dms' src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13805.512884157895!2d31.342659!3d30.111989!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x145815c32b608f4d%3A0xc75f47cdf2d6f58c!2zMTIg2LnYqNivINin2YTYudi42YrZhSDYudmI2LYg2KfZhNmE2YfYjCDYp9mE2YXYt9in2LHYjCDZgtiz2YUg2KfZhNmG2LLZh9ip2Iwg2YXYrdin2YHYuNipINin2YTZgtin2YfYsdip4oCsIDQ0NzAzNDDYjCDZhdi12LE!5e0!3m2!1sar!2sus!4v1692089805468!5m2!1sar!2sus"
                    width="100%" 
                    height="400" 
                    style={{border:"0"}} 
                    allowfullscreen="" 
                    loading="lazy" 
                    referrerpolicy="no-referrer-when-downgrade">
                 </iframe>
              </div>

          <div className="col-md-12 my-5">
           <h2 className={`${styles.movingHeader} text-center headerAnimated w-50 m-auto`} id='tech'>Technical Support <i className="fa-regular fa-circle-down "></i></h2>
           <div className={`${styles.paragHover} text-white p-3 paragUp1`} id='techHide' > 
           <h5>Online System Technical Support:</h5>
           <p>Our customer comes first, and our call center agents are here to welcome any suggestion, and handle complaints with care. You can reach us by different contact options, and we would be pleased to hear from you:</p>
           <h5>1. LANDLINE</h5>
           <p>To talk to our indoor technical support, call us on 26401444, 26381814 or 26371716 <br />Sunday through Thursday from 9:00 am to 5:00 pm.</p>
           <h5>2. EMAIL:</h5>
           <p>Email us on It@dms-eg.com and we will get back to you as soon as possible.</p>
           <h5>3. CELLPHONE:</h5>
           <p>To talk to our outdoor technical support, call us on 01025568370, 01020219623, 01129471765, 01096333352 or 01096333351 <br /> Saturday through Friday from 9:00 am to 11:00 pm.</p>
           </div>
         </div>

         
          </div>


      </div>
    </section>
  </>
  )
}
