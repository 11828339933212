import React from 'react'
import styles from '../PageTwoFolder/PageTwo.module.css'
import img1 from '../Media/dnaImg.webp'
// import AOS from "aos";
// import 'aos/dist/aos.css'
import { useEffect } from 'react';
import $ from 'jquery'
import purpleBG from '../Media/purpleBG.mp4'
import { Link } from 'react-router-dom';

export default function PageTwo() {

  
    useEffect(() => {

      let sideNav = $('#successPartner').offset().top;
      $(window).scroll(function (){
        let wScroll = $(window).scrollTop();
      
        if(wScroll > sideNav -20) {
          $('.sideNavColor').css('color' , '#5B005A')
        }
        else {
          $('.sideNavColor').css('color' , 'white')
        }
      })

      let saveLives = $('#saveLives').offset().top;
      $(window).scroll(function (){
        let wScroll = $(window).scrollTop();
      
        if(wScroll > saveLives -20) {
          $('#sideNav').css('display' , 'none')
        }
        else {
          $('#sideNav').css('display' , 'block')
        }
      })
          
        // //////////////////////////////////////////////////////
        // let pageTwo = $('.pageTwo').offset().top;
        // $(window).scroll(function (){
        //   let wScroll = $(window).scrollTop();
        
        //   if(wScroll > pageTwo - 50) {
        //     $('#aboutParag').addClass(' animate__animated animate__lightSpeedInRight')
        //   }
        //   else {
        //     $('#aboutParag').removeClass(' animate__animated animate__lightSpeedInRight')
        //   }
        // })

        // $(window).scroll(function (){
        //     let wScroll = $(window).scrollTop();
          
        //     if(wScroll > pageTwo - 50) {
        //       $('#aboutParagImg').addClass(' animate__animated animate__lightSpeedInLeft')
        //     }
        //     else {
        //       $('#aboutParagImg').removeClass(' animate__animated animate__lightSpeedInLeft')
        //     }
        //   })
          ///////////////////////////////////////////////////////////
          let fadingShadow = $('#fadingShadow').offset().top;
          $(window).scroll(function (){
            let wScroll = $(window).scrollTop();
          
            if(wScroll > fadingShadow ) {
              $('#bgFading').fadeOut(3000);
            }
            else {
              $('#bgFading').fadeIn(100);
            }
          })
        /////////////////////////////////////////////////////////////
        $('#homePageSideNav').click(function(){
          let sectionOneOffset = $('#homePage').offset().top;
          $('html,body').animate({scrollTop:sectionOneOffset} , 100)
         })
 
         $('#aboutUsSideNav').click(function(){
          let sectionTwoOffset = $('#aboutUs').offset().top;
          $('html,body').animate({scrollTop:sectionTwoOffset} , 100)
         })

         $('#successPartnerSideNav').click(function(){
          let sectionThreeOffset = $('#successPartner').offset().top;
          $('html,body').animate({scrollTop:sectionThreeOffset} , 100)
         })

         $('#missionVisionSideNav').click(function(){
          let sectionFourOffset = $('#missionVision').offset().top;
          $('html,body').animate({scrollTop:sectionFourOffset} , 100)
         })

         $('#saveLivesSideNav').click(function(){
          let sectionFiveOffset = $('#saveLives').offset().top;
          $('html,body').animate({scrollTop:sectionFiveOffset} , 100)
         })

         $('#profileSideNav').click(function(){
          let sectionSixOffset = $('#profile').offset().top;
          $('html,body').animate({scrollTop:sectionSixOffset} , 100)
         })
 
         

      }, [])
 
     
  return ( 
  <>  
        {/* id='sectonTwoID' */}
          <section id='aboutUs'  className={`pageTwo`} >

            <div className={`${styles.videoContainer}`} id='seconedSection'>

              <video width="100%" height="100%" playsInline autoPlay muted loop className={`${styles.videoEditBG}`} >
                <source src={purpleBG} type="video/mp4" />  
              </video>
              <div className={`${styles.videoContent}`}> 
                      <div className='container'>
                        <div className="row">
                        <div className={`${styles.zIndexOfAboutUs} text-center fs-2 fw-bolder position-relative mainColor text-white`}>
                               Introducing DMS
                          
                        </div>
                            <div id='aboutParag' className="col-md-6 d-flex align-items-center" >
                            <p className={`${styles.zIndexOfAboutUs} text-black text-white`}>Since 2002 we have been providing tailor made Medical Health Care and employee benefit plans as we as high quality service to hundreds of thousands of plan members. At DMS, the company offers a wide range of health insurance products at affordable prices to make health insurance every human being’s right. And as a company, single-mindedly dedicated to health insurance. Simple language, clear policies, transparent procedures and innovative products, making health insurance the way it ought to be. We know health care. <span className='aboutParag'>We know <span className='pageTwo2' id='fadingShadow'>insurance</span> .</span> </p>
                            </div>
                            <div className="col-md-6" >
                                <div id='aboutParagImg'>
                                    <img src={img1} alt="dna" className='w-50'/>
                              </div>
                            
                            </div>


                        <div className={`${styles.aboutUsParag} d-flex justify-content-center mt-3 `}>
                            <div id='aboutParag2' className={`${styles.boxShadow} col-md-3 my-3 text-white p-3 `}>
                              <h5>PRIMARY CARE</h5>
                              <p>Far far away, behind the word mountains, far from the countries Vokalia.</p>
                            </div>
                            <div id='aboutParag3' className={`${styles.boxShadow} col-md-3 my-3 text-white p-3`}>
                              <h5>LAB TEST</h5>
                              <p>Far far away, behind the word mountains, far from the countries Vokalia.</p>
                            </div>
                            <div id='aboutParag4' className={`${styles.boxShadow} col-md-3 my-3 text-white p-3`}>
                              <h5>SYMPTOM CHECK</h5>
                              <p>Far far away, behind the word mountains, far from the countries Vokalia.</p>
                            </div>
                            <div id='aboutParag5' className={`${styles.boxShadow} col-md-3 my-3 text-white p-3`}>
                              <h5>HEART RATE</h5>
                              <p>Far far away, behind the word mountains, far from the countries Vokalia.</p>
                            </div>

                        </div>

                        </div>

                    </div>

              </div>

            </div>


            <div className={`${styles.bgFading}`} id='bgFading'> </div>
            
             
          </section> 

    <nav id='sideNav'  className={`${styles.sideNav}`}>

          <div><Link id="homePageSideNav" className={`${styles.anchorSideNav} sideNavColor`}>Home page</Link> </div>
          <div><Link id="aboutUsSideNav" className={`${styles.anchorSideNav} sideNavColor`}>About Us</Link> </div>
          <div><Link id="successPartnerSideNav" className={`${styles.anchorSideNav} sideNavColor`}>Success Partners</Link></div>
          <div><Link id="missionVisionSideNav" className={`${styles.anchorSideNav} sideNavColor`}>Mission & Vision</Link></div>
          <div><Link id="saveLivesSideNav" className={`${styles.anchorSideNav} sideNavColor`}>Save Lives</Link></div>
          <div><Link id="profileSideNav" className={`${styles.anchorSideNav} sideNavColor`}>Profile</Link></div>

    </nav>
         
  </>
  )
}
