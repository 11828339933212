import React from 'react'
import styles from '../DepartmentFolder/Department.module.css'


export default function DepartmentDetails2() {
  return (
    <>
    <section className={`${styles.bgImg}`}>
    <div className="container">
         <div className="row py-5">

         <div className="col-md-12 my-5">
           <h3 className={`${styles.movingHeader} text-center headerAnimated`}>Indemnity</h3>
           <div className={`${styles.paragHover} text-white border p-3`}> 
           <h5>Indemnity Deparments:</h5>
           <p>1- In case of submitting a claim, from the treating physician, sending the original stamped payment receipt or a prescription stamped by the treating physician indicating the diagnosis.</p>
           <p>2- In case of submitting a claim for analyzes and x-rays, send the original stamped payment receipt along with sending a prescription from the treating doctor showing the tests that are being performed and sending the results of the tests and a report from the treating doctor explaining the diagnosis</p>
           <p>3- In case of submitting a claim for physiotherapy sessions, send a medical report in the case from the attending physician showing the diagnosis and the number of sessions that are conducted, and send the original payment receipt stamped from the center showing the price of each session</p>
           <p>4- In case of submitting a drug claim, sending a prescription from the treating doctor and sending the original invoice stamped from the pharmacy.</p>
           <p>5- In case of submitting a monthly medication claim, it will be dispensed within the medical network only and not through the Payment and Refund Department.</p>
           <p>6- In case of submitting a claim for medical glasses, send the optometry and send the original sealed payment receipt</p>
           <p>7- In the case of submitting a claim for medical glasses, send the optometry and send Stamped original payment receipt</p>
           <p>8- In case of submitting a dental claim, send the original payment stamped indicating the services that are being performed with an explanation of the molar number</p>
           <p>9- In case of submitting a claim from the hospital as an inpatient case: send the original cash payment receipt and send the original detailed invoice stamped from the hospital and a report from the hospital, all cases of hospitalization and conducting any operations except for emergency cases, a prior approval must be obtained from the medical department first and also sent Consent form with claim</p>
           <p>10- In case of submitting a claim for pregnancy and birth Send an ultrasound or analysis (photocopy or original) indicating the date of the start of pregnancy.</p>
           <p>11- In case of submitting a claim for pregnancy follow-up: Send the original stamped payment receipt or a prescription stamped by the attending physician</p>
           <p>12- In case of submitting a birth claim: send an original detailed and stamped invoice, continue a cash payment receipt, and send a report showing the type of birth.</p>
           <p>13- In case of submitting any claim, it must be sent within a period not exceeding 60 days from the date of performing the service.</p>
           </div>
       </div>
 
         </div>
    </div>
    </section>
    
    </>
  )
}
