import React from 'react'
import styles from '../DepartmentFolder/Department.module.css'


export default function DepartmentDetails1() {
  return (
    <>
    <section className={`${styles.bgImg}`}>
    <div className="container">
         <div className="row py-5">

             <div className="col-md-12 my-5">
           <h3 className={`${styles.movingHeader} text-center headerAnimated`}>Medical</h3>
           <div className={`${styles.paragHover} text-white border p-3`}>
              <div> 
              <h5>Medical approvals:</h5>
              <p >Medical approvals department provides services to our valued around the clock seven days a week, with the department providing approvals for outpatient services department to 5 pm and after that approvals for emergencies only.</p>
              </div>
              <div > 
              <h5>To get medical approval:</h5>
              <p>Please send email or fax attached to a copy of the services to be obtain approval</p>
              <p>The email of approvals (Operations - Analysis - rumors - Physical Therapy)</p>
              <p>Dms.medical1@gmail.com & dms.medical@gmail.com</p>
              <p>Special approvals medicines email</p>
              <p>dms.pharmacy1@gmail.com</p>
              <p>Special approvals Dental email</p>
              <p>dms.dental1@gmail.com</p>
              <p>Fax approvals (Operations - Analysis - X-rays - Physical therapy - medicines):- 26401666</p>
              <p>The ASO companies' email (Operations - Analysis - X-rays - Physical therapy - medicines): -</p>
              <p>Dms.medical1@gmail.com & dms.medical@gmail.com &ASOapprovals@dms-eg.com</p>
              </div>
              <div > 
              <h5>Important Instructions:</h5>
              <p>Must be a fax or email the sender that the attachment be clear and must be sent medical figure or image of medical card and clear and should clarify the diagnosis and then for the rays and analysis and operations.</p>
              <p>Must be a fax or email the sender that the attachment be clear and must be sent medical figure or image of medical card and clear and should clarify the diagnosis and clarify the names of the required medicines and determine the required dose, and so for the Muagaqat medicine.</p>
              <p>Must be a fax or email the sender that the attachment be clear and must be sent medical figure or image of medical card and clear and must locate and internecine issue and that for the approval teeth.</p>
              <h5>Important Note:</h5>
              <p>You can get all the approvals except inpatient approvals processes through any our providers which use online system without having to send any email or fax. To find out electronic medical network please click on this key</p>
              </div>
           </div>
          
             </div>
 
         </div>
    </div>
    </section>
    
    </>
  )
}
