import React from 'react'
import styles from '../FooterFolder/Footer.module.css'
import logo from '../Media/dmslogo.png'


export default function Footer() {
  return (
    <>

 


    <footer className={`${styles.footerBG}`}>

             <div className="container-fluid">
                <div className="row">
                  <div className="col-md-6 p-3">
                      <div>
                        <img src={logo} alt="dms" className='w-25 '/>
                      </div>
                      <div>
                        <p className='text-white p-3'>Your health is our top priority with comprehensive affordable medical ,            
                                                  Since 2002 we have been providing tailor made Medical Health Care and employee benefit plans 
                                                  as we as high quality service to hundreds of thousands of plan members
                        </p>
                      </div>
                  </div>
                  <div className="col-md-6 p-3 d-flex align-items-center justify-content-around">
                    <div>
                        <h2 className='text-white'>Contacts</h2>
                        <div>
                          <p className='text-white'>Address : 12 Abd El-Azeem Awad Allah St., Heliopolis, Cairo</p>
                          <p className='text-white'>Phone: : + 0226390390</p>
                          <p className='text-white'>Email: : Contact@dms-eg.com</p>
                        </div>
                    </div>

                  </div>
                       <div className='my-3 text-white fs-5 text-center'>
                    <i className="fa-regular fa-copyright"></i> DMS 2023. All Rights Reserved | Powered By Meccano
                    </div> 
                </div>
             </div>       

    </footer>
  
    </>
  )
}
