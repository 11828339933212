import React, { useState } from 'react'
import styles from '../PageOneFolder/PageOne.module.css'
import homeVid from '../Media/homepage-vid7.mp4'
import AOS from "aos";
import 'aos/dist/aos.css'
import { useEffect } from 'react';
import PageFive from '../PageFiveFolder/PageFive';
import PageFour from '../PageFourFolder/PageFour';
import PageThree from '../PageThreeFolder/PageThree';
import PageTwo from '../PageTwoFolder/PageTwo';
import PageSix from '../PageSixFolder/PageSix';
import $ from 'jquery'
import { Link } from 'react-router-dom';
import axios from 'axios';

export default function PageOne() {

  
  const [adsText, setAdsText] = useState([])
  async function getAdsTextData(){
   let {data} = await axios.get(`https://dms.up.railway.app/api/news/`);
   setAdsText(data)   
  //  console.log(data)
  }
  
                         
    useEffect(() => {
      getAdsTextData();
      ///////////////////////////////////////////////////////////////////
        AOS.init({duration: 2000})  
      ///////////////////////////////////////////////////////////////////
           $('#arrowMoveDown').click(function(){
            let sectionTwoOffset = $('#aboutUs').offset().top;
            $('html,body').animate({scrollTop:sectionTwoOffset} , 100)
           })
      ///////////////////////////////////////////////////////////////////
    
 
      }, [])

  return (  
   <>


    <header className={`${styles.videoContainer} vh-100`} id='homePage'>


<video width="100%" height="100%" playsInline autoPlay muted loop className={`${styles.videoEditBG}`} >
  <source src={homeVid} type="video/mp4" />
</video>
<div className={`${styles.videoContent}`} id='header' data-aos="zoom-in"> 


      <div className='d-flex justify-content-center align-items-center h-100'>
        <div> 
             <p className='text-white fw-bold fs-2'>We Care</p>   
             <p className='text-white fw-bold fs-2'>About Your Health.</p>
                <div className='text-white fs-4'> 
                    <Link   className={`${styles.arrowBG}`}>
                        <i className={`${styles.arrowEdit} fa-solid fa-arrow-down rounded-circle`} id='arrowMoveDown'></i> 
                    </Link>
                  <span className={`${styles.editSection} p-2`}>
                       <Link to='/services'>
                      <button className={`${styles.btnHomeEdit} btn mb-2`}>services</button>   
                      </Link> 
                          {adsText.map((textApi , i)=> <span key={i}>  
                             {textApi.title} 
                          </span>)}
 
                    {/* Lorem ipsum dolor sit, amet consectetur adipisicing elit. Corporis nesciunt ipsum     */}
                  </span>
                 
                </div>
        </div>
      </div>


</div>

    </header>

     <PageTwo/> 
     <PageFour/>
     <PageThree/>
     <PageFive/>
     <PageSix/>
   
   </>
  )
}
