import React from 'react'
import styles from '../NavFolder/Nav.module.css'
import { Link } from 'react-router-dom'
import logo from '../Media/dmslogo.png'
// import $ from 'jquery'


export default function Nav() {


      // useEffect(() => {
      //              $('#aboutUsLink').click(function(){
      //               $('#offcanvasNavbar').toggle(1)})
      // }, [])
      
  // useEffect(() => {

  //    let pageThreeOffset = $('#successPartners').offset().top;
     
  //   $(window).scroll(function (){
  //     let wScroll = $(window).scrollTop();
    
  //     if(wScroll > pageThreeOffset -20) {
  //       $('#navBG').css('backgroundColor' , '#5B005A')
  //     }
  //     else {
  //       $('#navBG').css('backgroundColor' , 'transparent')
  //     }
  //   })

  // }, [])



  return (
    <>
       
    <nav id='navBG' className={`${styles.navEdit} navbar fixed-top`}>
        
         <div className="container-fluid ">
          
        <div> <Link className={`navbar-brand`} to='home'> <img src={logo} alt="dms" className='w-50'/> </Link></div>

        <button className={`${styles.pinkbtn} navbar-toggler bg-white`} type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar" aria-label="Toggle navigation" id="offcanvasNavbar2">
        <span className={`navbar-toggler-icon`}></span>
        </button>
            
    <div className={`${styles.offCanvasResponsiveMobile} offcanvas offcanvas-end`} tabindex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel">
      <div className="offcanvas-header">
        <h5 className="offcanvas-title" id="offcanvasNavbarLabel">
             <Link className={`${styles.linkHover} fs-4`} to={'https://dms-eg.net/Account/Login'} target="_blank"> Login </Link>
        </h5>
        <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
      </div>
      <div className={`offcanvas-body`}>
        <ul className="navbar-nav justify-content-end flex-grow-1">
        <li className="nav-item text-center">

              <li className="nav-item dropdown ">
                  <Link  className={`${styles.linkHover} fs-5`} role="button" data-bs-toggle="dropdown" aria-expanded="false">
                           Patient Services    <i className="fa-solid fa-caret-down fs-5 "></i>
                  </Link>
                
                  <ul className="dropdown-menu text-center">
                    <li><Link className="dropdown-item" to={'https://dms-eg.net/Account/Login?ReturnUrl=%2FHrChronic%2FInquiryAboutCard'} target="_blank">Chronic Medication</Link></li>
                    <li><Link className="dropdown-item" to={'https://dms-eg.net/Account/Login?ReturnUrl=%2FEmployee%2FDeliveryService2'} target="_blank">Medication Delievery </Link></li>
                    <li><Link className="dropdown-item" to={'https://dms-eg.net/Account/Login?ReturnUrl=%2FIndemnitiesAdmin%2Findex'} target="_blank">Indemnity </Link></li>
                    <li><Link className="dropdown-item" to={'https://dms-eg.net/Account/Login?ReturnUrl=%2FMedicalNetwork%2FSearch'} target="_blank">Medical Network </Link></li>
                    <li><Link className="dropdown-item" to={'https://dms-eg.net/Account/Login?ReturnUrl=%2FEmployee%2FApprovales'} target="_blank">Medical Approval </Link></li>
                    <li><Link className="dropdown-item" to={'https://dms-eg.net/Account/Login?ReturnUrl=%2FEmployee%2FApprovales#'} target="_blank">Help </Link></li>
                    <li><Link className="dropdown-item" to={'https://dms-eg.net/Account/Login'} target="_blank">Login(User/Hr) </Link></li>
                  </ul>
              </li>

            
        
               <div className='my-4'><Link className={`${styles.linkHover} fs-5`} to='aboutUs' id='aboutUsLink'> About Us  </Link></div>       
               <div className='my-4'><Link className={`${styles.linkHover} fs-5`} to='services'> Dms Services </Link></div> 
               <div className='my-4'><Link className={`${styles.linkHover} fs-5`} to='department'>  Departments </Link></div>
               <div className='my-4'> <Link className={`${styles.linkHover} fs-5`} to='onlineSystem'> Online System </Link></div> 
               <div className='my-4'><Link className={`${styles.linkHover} fs-5`} to='ourClients'> Our Clients </Link></div>      
               <div className='my-4'><Link className={`${styles.linkHover} fs-5`} to='contactUs'> Contact Us </Link></div> 
               <div className='my-4'> <Link className={`${styles.linkHover} fs-5`} to='https://dms-eg.net/Home/Search' target="_blank"> Our Network </Link></div> 
         
              
        </li>
        </ul>

      </div>
    </div>
        </div>
    </nav>



    </>
  )
}
