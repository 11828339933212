import React, { useEffect } from 'react'
import styles from '../AboutUsFolder/AboutUs.module.css'
import $ from 'jquery'
import aboutUs from '../Media/orgnaization chart.webp'
import aboutUs2 from '../Media/graph.webp' 
import 'animate.css';
// import { useLocation } from 'react-router-dom';


export default function AboutUs() {

    

    useEffect(() => {
        
        $('.headerAnimated').click(function(){
            $(this).next().slideToggle(500);
            // $('.paragUp1').not($(this).next()).slideUp(500); 
        })

      }, [])

  return (
   <> 
  
    
   <section className={`${styles.colorAnimatedBG} py-5`}>

   <div className="container ">

        <div className="row mb-5">
            <h2 className='text-center my-5 fw-bold text-white'>History
                    <div className="longline mt-1"></div>
                    <div className="shortline mt-1"></div>
                  
            </h2>

 

            <div className="col-md-6 animate__animated animate__zoomIn">
                <h5 className={`${styles.movingHeader} text-center headerAnimated`}>Foundation</h5>
                <p className={`${styles.paragHover} text-white text-center border p-3`}>Founded in 2002 by health care industry veterans who seeked to offer health care cost containment solutions to clients from all over the world,In order to address this issue,has established a network that enables cooperates requiring health care treatment for their employee.</p>
            </div>
            <div className="col-md-6 animate__animated animate__zoomIn">
                <h5 className={`${styles.movingHeader} text-center headerAnimated`}>Capital</h5>
                <p className={`${styles.paragHover} text-white text-center border p-3`}>D.M.S works with a capital of 102,000,000 Egyptian Pounds with increasingly sales growth volume each year. With an average number Of clients equals around 120 company with total number of insured equivalent to 160.000 LE Subscribers</p>
            </div>
            <div className="col-md-12 animate__animated animate__zoomIn">
                <h5 className={`${styles.movingHeader} text-center headerAnimated`}>Network</h5>
                <p className={`${styles.paragHover} text-white text-center border p-3`}>DMS network gives your employees broad access to physicians, specialists and hospitals with such an extensive network, enrollees can be assured that they will be covered all over Egypt never the less. He will be covered anywhere in the world and our payors can count on assistance and cost control on a global scale. Whether an insured need a simple referral for outpatient treating, long term housing or emergency, we are there.</p>             
            </div>

        </div>

        <div className={`${styles.whiteBG} row mt-5 mb-3 animate__animated animate__zoomIn`}>
            
            <div className="col-md-4 d-flex align-items-center justify-content-center">
                 <h2 className={`${styles.purpleColor} fw-bold fs-2`}>Organization Chart  
                    <div className="longline2 mt-1"></div>
                    <div className="shortline2 mt-1"></div>
                  
                 </h2>
            </div>
            <div className="col-md-8 d-flex justify-content-end">
                <div className=''>
                     <img src={aboutUs} alt="" className='img-fluid'/>
                </div>
               
            </div>

        </div>

        <div className="row my-3">
            <h2 className='text-center my-5 fw-bold text-white'>Our Keys Of Success
                   <div className="longline mt-1"></div>
                    <div className="shortline mt-1"></div>
                   
            </h2>

            <div className="col-md-4 animate__animated animate__zoomIn">
                <h5 className={`${styles.movingHeader} text-center headerAnimated`}>DMS targeted a niche market</h5>
                <p className={`${styles.paragHover} text-white text-center border p-3`}> Large and multinational organizations, providing first class medical expenses protection plans that meet the evolving requirements of the customers and their employees.</p>             
            </div>
            <div className="col-md-4 animate__animated animate__zoomIn">
                <h5 className={`${styles.movingHeader} text-center headerAnimated`}>The wide network</h5>
                <p className={`${styles.paragHover} text-white text-center border p-4`}>The wide network of shareholders and management team which has been the backbone behind the Company’s success in acquiring the impressive client list.</p>
            </div>
            <div className="col-md-4 animate__animated animate__zoomIn">
                <h5 className={`${styles.movingHeader} text-center headerAnimated`}>Good Reputation</h5>
                <p className={`${styles.paragHover} text-white text-center border p-3`}>That D.M.S has achieved in this short time has ensured maintenance of good relations with all concerned parties: clients, brokers and medical network as a stable strong credible payee.</p>
            </div>

        </div>

        <div className="row my-3">
            <h2 className='text-center my-5 fw-bold text-white'>Mission Statment
                    <div className="longline mt-1"></div>
                    <div className="shortline mt-1"></div>
                  
            </h2>

            <div className="col-md-6 animate__animated animate__zoomIn">
                <h5 className={`${styles.movingHeader} text-center headerAnimated`}>Maintain</h5>
                <p className={`${styles.paragHover} text-white text-center border p-5`}>Maintain a healthy working environment for our employees that foster creativity, integrate growth and team work .To be the kind of company people are proud to work with.</p>             
            </div>
            <div className="col-md-6 animate__animated animate__zoomIn">
                <h5 className={`${styles.movingHeader} text-center headerAnimated`}>Help</h5>
                <p className={`${styles.paragHover} text-white text-center border p-5`}>Help our customers achieve their business objectives by providing them with Health care in an effective and efficient way.</p>
            </div>


        </div>

        <div className={`${styles.whiteBG} row mt-5 p-3 animate__animated animate__zoomIn`}>

            <div className="col-md-4 d-flex align-items-center justify-content-center">
                 <h2 className={`${styles.purpleColor} fw-bold fs-1`}>DMS Growth 
                    <div className="longline2 mt-1"></div>
                    <div className="shortline2 mt-1"></div>
                             
                  </h2>
            </div>
            <div className="col-md-8 d-flex justify-content-end">
                <div className='mb-1'>
                     <img src={aboutUs2} alt="dms" className='img-fluid'/>
                </div>
            </div>

        </div>
    </div>

   </section>
 
   </>
  )
}
