import React, { useEffect } from 'react'
import styles from '../ServicesFolder/Services.module.css'
import $ from 'jquery'
import { Link } from 'react-router-dom'
import 'animate.css';

export default function Services() {

    useEffect(() => {
        
        $('.headerAnimated').click(function(){
            $(this).next().slideToggle(500);
            // $('.paragUp1').not($(this).next()).slideUp(500);  
        })

      }, [])

  return (
   <>
      <section className={`${styles.colorAnimatedBG} pt-5 vh-100`}>

        <div className="container">

             <div className="row">
         <h2 className='text-center mt-5 fw-bold text-white'>DMS Services
                    <div className="longline mt-1"></div>
                    <div className="shortline mt-1"></div>
                    
         </h2>



             <div className="container my-5">
                <div className={`${styles.mobileResponsive} row d-flex justify-content-center`}>
                    <div className="col-md-4 my-3 animate__animated animate__bounceInLeft">
                        <div className={`${styles.contactUsIconsPosition} pt-5 text-center position-relative p-5 `}>
                            <h2 className={`${styles.purpleColor}`}>Medical Care</h2>                 
                            <p className={`${styles.purpleColor}`}>Managed Medical Care Services: we combine a team of highly trained claim analysts along with medical staff specialized in managing ...</p>
                            <p  className={`${styles.purpleColor} fw-bold`}>
                                <Link to={'/servicesDetails'} className={`${styles.LinkEdit}`}> see more <i className="fa-regular fa-circle-down "></i> </Link>
                           </p>
                            <div className={`${styles.iconsBorder} position-absolute top-0 start-50 translate-middle p-2`}>
                            <i className={`${styles.iconColor} fa-solid fa-hand-holding-medical fs-1`}></i>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 my-3 animate__animated animate__bounceIn">
                        <div className={`${styles.contactUsIconsPosition} pt-5 text-center position-relative p-5 `}>
                            <h2 className={`${styles.purpleColor}`}>Travel insurance</h2>                 
                            <p className={`${styles.purpleColor}`}>Given the international nature of most of our corporate clients and our keenness to meet their every need, we offer them travel ... </p>
                            <p  className={`${styles.purpleColor} fw-bold`}>
                                <Link to={'/servicesDetails2'} className={`${styles.LinkEdit}`}> see more  <i className="fa-regular fa-circle-down "></i></Link>
                           </p>
                            <div className={`${styles.iconsBorder} position-absolute top-0 start-50 translate-middle p-2`}>
                            <i className={`${styles.iconColor} fa-solid fa-house-crack fs-1`}></i>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4 my-3 animate__animated animate__bounceInRight">
                        <div className={`${styles.contactUsIconsPosition} pt-5 text-center position-relative p-5 `}>
                            <h2 className={`${styles.purpleColor}`}>Medical Discount</h2>                 
                            <p className={`${styles.purpleColor}`}>An innovated system designed to serve the growing demands of clients willing to benefit from our services at a minimum price...</p>
                            <p  className={`${styles.purpleColor} fw-bold`}>
                                <Link to={'/servicesDetails3'} className={`${styles.LinkEdit}`}> see more <i className="fa-regular fa-circle-down "></i> </Link>
                           </p>
                            <div className={`${styles.iconsBorder} position-absolute top-0 start-50 translate-middle p-2`}>
                            <i className={`${styles.iconColor} fa-thin fa-percent fs-3 mx-2`}></i>
                          
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            </div>


        </div>

    </section>
   
   
   </>
  )
}
