import React from 'react'
import styles from '../ServicesFolder/Services.module.css'

export default function ServicesDetails3() {
  return (
    <>
    <section className={`${styles.bgImg} vh-100`}>
    <div className="container">
         <div className="row">

 
          <div className="col-md-12 my-5 pt-5">
              <h3 className={`${styles.movingHeader} text-center headerAnimated mt-5`}>Medical Discount</h3>
              <div className={`${styles.paragHover} bg-white border p-3`}> 
              <h5>(on cash basis with the benefit of using the discounted prices)</h5>
              <p>An innovated system designed to serve the growing demands of clients willing to benefit from our services at a minimum price. Where a distinct card is issued to individuals upon their request. This card when presented at any of our provider venues will entitle its holder to a markedly discounted price (only exclusively offered to our clients) for his required service regardless of the number of services or their frequency or the individual's demography, within a specific period of time. A convenient approach efficiently serving you, bypassing time-wasting steps and paper work. So whether you have acquaintances, relatives or friends seeking medical care services yet unable to meet its relentless quota, this approach is the answer.</p>
              </div>
          </div> 
         </div>
    </div>
    </section>
    
    </>
  )
}
