import React from 'react'
import styles from '../ServicesFolder/Services.module.css'

export default function ServicesDetails() {
  return (
    <>
    <section className={`${styles.bgImg} vh-100`}>
    <div className="container">
         <div className="row">
            <div className="col-md-12 my-5 pt-5">
                <h3 className={`${styles.movingHeader} text-center headerAnimated mt-5`}>Medical Care</h3>
                <div className={`${styles.paragHover} bg-white border p-3`}>
                  <div> 
                  <h5>Managed Medical Care Services:</h5>
                  <p >(Corporate & individual) we combine a team of highly trained claim analysts along with medical staff specialized in managing cases. Every single procedure handled with caution , Every step taken with care , Tailored offers to suit our customers individuality , Flexible implementation of terms upon mutual understanding and An impeccable medical sense given the fact that all our departments are either partly or completely staffed with physicians , thus guaranteeing you conscience driven judgments rather than cold apathetic calculations.</p>
                  </div>
                  <div > 
                  <h5>Administration Service only:</h5>
                  <p>(cost control) actual expenses added to it admiration fees. This is managed by strong claim adjustor staff that detect over charges, erroneous or fraudulent claims and cases that are susceptible to over billing. Thereby providing an editorial quality to our services which provides you with protection against misuse, allocating your resources to optimum service. We aim to provide all & nbsp; with adequate care not a limited few at the expense of the whole</p>
                  </div>
                  <div > 
                  <h5>Hotline service:</h5>
                  <p>provided to you 24/7, which is handled by specialized physicians and experienced members providing you with swift responses to your every query .So no matter the dilemma you find yourself faced with, we are always within your reach and at your service</p>
                  </div>
                </div>
              
            </div>
 
         </div>
    </div>
    </section>
    
    </>
  )
}
