import React from 'react'
import styles from '../DepartmentFolder/Department.module.css'


export default function DepartmentDetails3() {
  return (
    <>
    <section className={`${styles.bgImg}`}>
    <div className="container">
         <div className="row py-5">

           <div className="col-md-12 my-5">
           <h3 className={`${styles.movingHeader} text-center headerAnimated`}>Operation </h3>
           <div className={`${styles.paragHover} text-white border p-3`}> 
           <h5>Operation Deparments:</h5>
           <p>The department enters information about new customers or who renew the system, and reviews the cards forms and gives them to the printing department, which is responsible for deleting, adding or modifying customer information when making changes in companies, and is also responsible for building the company's policies and services available to customers on the system, which is the means of communication Between the company and the airports and meeting with them to know their proposal and follow up with them.</p>
           <p>In the Operations Department, there are three sections New operation , edit operation and termination operation Let's start with the first section New employee : to be covered by insurance. The HR must fill this data like his full name ( English & Arabic ) his email, national id , gender , extra… Edit employee : the HR must fill this data like edit level,re-open,re-print,change employee number & change name if it is necessary Termination request : the HR must fill this data card id ,termination date&has the card been received to the HR or not !! to close the card id</p>
           </div>
           </div>
 
         </div>
    </div>
    </section>
    
    </>
  )
}
