import React from 'react'
import styles from '../OurClientsFolder/OurClients.module.css'
import 'animate.css';

export default function OurClients() {



  return (
    <>

    <section className={`${styles.colorAnimatedBG} pt-5`}>

      <div className="container">

           <div className="row">
                <h2 className='text-center mt-5 fw-bold text-white'>Our Clients
                            <div className="longline mt-1"></div>
                            <div className="shortline mt-1"></div>
                            
                </h2>
           
           <div className={`${styles.mediaResponsive} col-md-12 d-flex justify-content-between my-5`}>

                <div className={`${styles.paragHover} text-white paragUp1 animate__animated animate__rotateIn`}> 
                <div className={`${styles.movingHeader} d-flex justify-content-between align-itmes-center headerAnimated`}>
                <h5> Clients	</h5>
                <h5> Counts	</h5>
                </div>
                <div className='d-flex justify-content-between align-itmes-center border p-1'>
                    <div>Financial companies</div>
                    <div >13</div>
                </div>
                <div className='d-flex justify-content-between align-itmes-center border p-1'>
                    <div>Banks</div>
                    <div >1</div>
                </div>
                <div className='d-flex justify-content-between align-itmes-center border p-1'>
                    <div>Insurance companies</div>
                    <div >2</div>
                </div>
                <div className='d-flex justify-content-between align-itmes-center border p-1'>
                    <div className='me-5'>Transport & Navigation companies</div>
                    <div >5</div>
                </div>
                <div className='d-flex justify-content-between align-itmes-center border p-1'>
                    <div>Food & Beverages companies</div>
                    <div >10</div>
                </div>
                <div className='d-flex justify-content-between align-itmes-center border p-1'>
                    <div>Textile & Wear industries</div>
                    <div >5</div>
                </div>
                <div className='d-flex justify-content-between align-itmes-center border p-1'>
                    <div>Heavy industries</div>
                    <div >20</div>
                </div>
                <div className='d-flex justify-content-between align-itmes-center border p-1'>
                    <div>Petroleum companies</div>
                    <div >2</div>
                </div>                    
                </div>
                <div className={`${styles.paragHover} text-white paragUp1 animate__animated animate__rotateIn`}> 
                <div className={`${styles.movingHeader} d-flex justify-content-between align-itmes-center headerAnimated`}>
                <h5> Clients	</h5>
                <h5> Counts	</h5>
                </div>
                <div className='d-flex justify-content-between align-itmes-center border p-1'>
                    <div>Pharmaceutical companies</div>
                    <div >5</div>
                </div>
                <div className='d-flex justify-content-between align-itmes-center border p-1'>
                    <div>Governmental organizations	</div>
                    <div >4</div>
                </div>
                <div className='d-flex justify-content-between align-itmes-center border p-1'>
                    <div>Civil society organization	s</div>
                    <div >5</div>
                </div>
                <div className='d-flex justify-content-between align-itmes-center border p-1'>
                    <div>Education	</div>
                    <div >2</div>
                </div>
                <div className='d-flex justify-content-between align-itmes-center border p-1'>
                    <div>IT companies	</div>
                    <div >8</div>
                </div>
                <div className='d-flex justify-content-between align-itmes-center border p-1'>
                    <div>Engineering consultations	</div>
                    <div >5</div>
                </div>
                <div className='d-flex justify-content-between align-itmes-center border p-1'>
                    <div>Researches companies	</div>
                    <div >9</div>
                </div>
                <div className='d-flex justify-content-between align-itmes-center border p-1'>
                    <div className='me-5'>Printing and packaging factories & companies</div>
                    <div >9</div>
                </div>
                </div>
                <div className={`${styles.paragHover} text-white paragUp1 animate__animated animate__rotateIn`}> 
                <div className={`${styles.movingHeader} d-flex justify-content-between align-itmes-center headerAnimated`}>
                <h5> Clients	</h5>
                <h5> Counts	</h5>
                </div>

                <div className='d-flex justify-content-between align-itmes-center border p-1'>
                    <div>Agencies</div>
                    <div >30</div>
                </div>
                <div className='d-flex justify-content-between align-itmes-center border p-1'>
                    <div>Stores</div>
                    <div >9</div>
                </div>
                <div className='d-flex justify-content-between align-itmes-center border p-1'>
                    <div className='me-5'>Telecommunications companies</div>
                    <div >7</div>
                </div>
                <div className='d-flex justify-content-between align-itmes-center border p-1'>
                    <div>Tourism & Travel companies	</div>
                    <div >10</div>
                </div>
                <div className='d-flex justify-content-between align-itmes-center border p-1'>
                    <div>Hotels and Restaurants	</div>
                    <div >0</div>
                </div>
                <div className='d-flex justify-content-between align-itmes-center border p-1'>
                    <div>Media companies	</div>
                    <div >9</div>
                </div>
                <div className='d-flex justify-content-between align-itmes-center border p-1'>
                    <div className='me-5'>Entertainment companies	</div>
                    <div >1</div>
                </div>
                <div className='d-flex justify-content-between align-itmes-center border p-3'>
                    <div></div>
                    <div ></div>
                </div>
                </div>

           </div>

     

          </div>


      </div>

    </section>
 
 
    </>
  )
}
