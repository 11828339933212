import React from 'react'
import 'aos/dist/aos.css'
import { useEffect } from 'react';
import styles from '../PageThreeFolder/PageThree.module.css'
import 'animate.css';
import $ from 'jquery'

export default function PageThree() {

    useEffect(() => {
        
        $('.headerAnimated').click(function(){
            $(this).next().slideToggle(500);
            // $('.paragUp1').not($(this).next()).slideUp(500);  // close (slide up) all exept this.next
        
        })

      }, [])


  return (
   <>
    
   
   <section className="py-5 my-5" id='missionVision'>
        <div className="container w-75 m-auto">
          <div className="row">
            <div className="col-md-12 ">
              <h2  className={`${styles.movingHeader} text-center headerAnimated`}>Our Mission</h2>
              <p className={`${styles.paragUp} text-center paragUp1`}>
                  <div className={`${styles.mobileResponsiveOurMissin} d-flex justify-content-center`}>
                    <p className={`${styles.shadowDiv} border p-5`} >  We offer personalized risk management solutions</p>  
                    <p className={`${styles.shadowDiv} border p-5`} > We provide peace of mind via personalized customer experience </p> 
                    <p className={`${styles.shadowDiv} border p-5`} > We offer a healthcare ecosystem to connect the bridge between health-to-care  </p>
                  </div>
              </p>
            </div>
            <div className="col-md-12">
              <h2  className={`${styles.movingHeader} text-center headerAnimated`}>Our Vision</h2>
              <div className={`${styles.paragUp} text-center paragUp1`}>
                 <p className={`${styles.shadowDiv2} border p-5`}>
                    DMS has brought together its global and local healthcare know-how and expertise in the healthcare ecosystem
                    industry to self-funded schemes, private companies, and government health plans The DMS concept presents a
                    unique full-service approach to managed care and health risk protection
                 </p>
                </div>
            </div>

          </div>
        </div>
      </section>
   </>
  )
}
