import React from 'react'
import styles from '../ServicesFolder/Services.module.css'

export default function ServicesDetails2() {
  return (
    <>
    <section className={`${styles.bgImg} vh-100`}>
    <div className="container">
         <div className="row">
 
          <div className="col-md-12 my-5 pt-5">
              <h3 className={`${styles.movingHeader} text-center headerAnimated mt-5`}>Travel Insurance</h3>
              <div className={`${styles.paragHover} bg-white border p-3`}> 
              <h5>Travel insurance:</h5>
              <p>Given the international nature of most of our corporate clients and our keenness to meet their every need, we offer them travel insurance services for the elite few likely to be on the move frequently and since the chances of medical emergencies or health crises remain the same regardless of an individual's location therefore exclusively local services are rendered of no value to them.So we stepped in to compensate our clients, In the sense that medical emergency expenditure will be refunded to them according to the agreed upon prices we preset or as a fixed percentage of the costs.Thus we are prepared to offer a sensible customized form of international coverage in return for an agreeable premium.</p>
              </div>
          </div>
 
         </div>
    </div>
    </section>
    
    </>
  )
}
