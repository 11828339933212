import React from 'react'
import styles from '../DepartmentFolder/Department.module.css'

export default function DepartmentDetails4() {
  return (
    <>
    <section className={`${styles.bgImg}`}>
    <div className="container">
         <div className="row py-5">

            <div className="col-md-12 my-5">
           <h3 className={`${styles.movingHeader} text-center headerAnimated`}>Finance </h3>
           <div className={`${styles.paragHover} text-white border p-3`}> 
           <h5>Finance Deparments:</h5>
           <p>The part of an organization that manages its money. The business functions of a finance department typically include planning, organizing, auditing, accounting for and controlling its company's finances. The finance department also usually produces the company's financial statements.</p>
           </div>
            </div>
 
         </div>
    </div>
    </section>
    
    </>
  )
}
