import React, { useEffect } from 'react'
import styles from '../DepartmentFolder/Department.module.css'
import $ from 'jquery'
import { Link } from 'react-router-dom'
import 'animate.css';


export default function Department() {

    useEffect(() => {
        
        $('.headerAnimated').click(function(){
            $(this).next().slideToggle(500);
            // $('.paragUp1').not($(this).next()).slideUp(500); 
        })

      }, [])

  return (

     <>
    <section className={`${styles.colorAnimatedBG} pt-5`}>

      <div className="container">

      <div className="row">
        <h2 className='text-center mt-5 fw-bold text-white'>DMS Departments
                        <div className="longline mt-1"></div>
                        <div className="shortline mt-1"></div>
                    
        </h2>
  
           <div className="col-md-6 my-5 animate__animated animate__lightSpeedInLeft">
              <div className={`${styles.contactUsIconsPosition} pt-5 text-center position-relative p-5 `}>
                            <h2 className={`${styles.purpleColor}`}>Medical</h2>                 
                            <p className={`${styles.purpleColor}`}>Medical approvals : Medical approvals department provides services to our valued around the clock seven days a week, with the department providing approvals for outpatient services department to 5 pm and after that approvals for ...</p>
                            <p  className={`${styles.purpleColor} fw-bold`}>
                                <Link to={'/departmentDetails1'} className={`${styles.LinkEdit}`}> see more <i className="fa-regular fa-circle-down "></i> </Link>
                           </p>
                            <div className={`${styles.iconsBorder} position-absolute top-0 start-50 translate-middle p-2`}>
                            <i className={`${styles.iconColor} fa-solid fa-hand-holding-medical fs-1`}></i>
                            </div>
              </div>
           </div>
           <div className="col-md-6 my-5 animate__animated animate__lightSpeedInRight">
              <div className={`${styles.contactUsIconsPosition} pt-5 text-center position-relative p-5 `}>
                            <h2 className={`${styles.purpleColor}`}>Operation</h2>                 
                            <p className={`${styles.purpleColor}`}>Operation Deparments : The department enters information about new customers or who renew the system, and reviews the cards forms and gives them to the printing department, which is responsible for deleting, adding or modifying  ...</p>
                            <p  className={`${styles.purpleColor} fw-bold`}>
                                <Link to={'/departmentDetails3'} className={`${styles.LinkEdit}`}> see more  <i className="fa-regular fa-circle-down "></i></Link>
                           </p>
                            <div className={`${styles.iconsBorder} position-absolute top-0 start-50 translate-middle p-2`}>

                            <i className={`${styles.iconColor} fa-solid fa-gear fs-1`}></i>
                            </div>
              </div>
           </div>
           <div className="col-md-6 my-5 animate__animated animate__lightSpeedInLeft">
              <div className={`${styles.contactUsIconsPosition} pt-5 text-center position-relative p-5 `}>
                            <h2 className={`${styles.purpleColor}`}>Indemnity</h2>                 
                            <p className={`${styles.purpleColor}`}>Indemnity Deparments : (1) In case of submitting a claim, from the treating physician, sending the original stamped payment receipt or a prescription stamped by the treating physician indicating the diagnosis ...</p>
                            <p  className={`${styles.purpleColor} fw-bold`}>
                                <Link to={'/departmentDetails2'} className={`${styles.LinkEdit}`}> see more  <i className="fa-regular fa-circle-down "></i></Link>
                           </p>
                            <div className={`${styles.iconsBorder} position-absolute top-0 start-50 translate-middle p-2`}>
                            <i className={`${styles.iconColor} fa-solid fa-dollar-sign fs-1 px-2`}></i>
                            </div>
              </div>
           </div>
           <div className="col-md-6 my-5 animate__animated animate__lightSpeedInRight">
              <div className={`${styles.contactUsIconsPosition} pt-5 text-center position-relative p-5 `}>
                            <h2 className={`${styles.purpleColor}`}>Finance</h2>                 
                            <p className={`${styles.purpleColor}`}>Finance Deparments : The part of an organization that manages its money. The business functions of a finance department typically include planning, organizing, auditing, accounting for and controlling  ...</p>
                            <p  className={`${styles.purpleColor} fw-bold`}>
                                <Link to={'/departmentDetails4'} className={`${styles.LinkEdit}`}> see more  <i className="fa-regular fa-circle-down "></i></Link>
                           </p>
                            <div className={`${styles.iconsBorder} position-absolute top-0 start-50 translate-middle p-2`}>
                            <i className={`${styles.iconColor} fa-solid fa-coins fs-1`}></i>
                            </div>
              </div>
           </div>

          </div>


      </div>

    </section>
 
 
     </>
  )
}
