import React, { useEffect } from 'react'
import styles from '../PageFiveFolder/PageFive.module.css'
import vid2 from '../Media/section-vid.mp4'
import { Link } from 'react-router-dom'
import $ from 'jquery'
import 'animate.css';


export default function PageFive() {

  useEffect(() => {
   

    let saveLives = $('#saveLives').offset().top;
    $(window).scroll(function (){
      let wScroll = $(window).scrollTop();

      if(wScroll > saveLives -50) {
        $('#saveLivesParag').addClass('mainColor animate__animated animate__fadeInTopRight')
      }
      else {
        $('#saveLivesParag').removeClass('mainColor animate__animated animate__fadeInTopRight')
      }
    })
  }, [])

    
  return (
    <>
     <section className={`${styles.pageFiveEditSpace} container-fluid`} id='saveLives'>
     
        <div className="row">

          
            <div className="col-md-6">
                 <video width="100%" height="100%" playsInline autoPlay muted loop >
                    <source src={vid2} type="video/mp4" />
                 </video> 
            </div>
            <div className={`${styles.PageFiveBg} col-md-6 d-flex justify-content-center align-items-center text-white`}>
                <div id='saveLivesParag'>
                    <p className={`${styles.colorPurple} fs-1 fw-bold`}>We Are Here</p>
                    <p className={`${styles.colorPurple} fs-1 fw-bold`}>To Support</p>
                    <p className={`${styles.colorPurple} fs-1 fw-bold`}>You</p>

                    <button className={`${styles.BtnEdit} mt-5 mb-3 p-3 btn`}> <Link  to={'ContactUs'} className={`${styles.BtnEdit} fs-3 fw-bold`}>Contact Us</Link>  </button>
                </div>

            </div>
        </div>
    </section> 
    </>
  )
}
