import React, { useEffect } from 'react'
import styles from '../OnlineSystemFolder/OnlineSystem.module.css'
import $ from 'jquery'

export default function OnlineSystem() {

    useEffect(() => {
        
        $('.headerAnimated').click(function(){
            $(this).next().slideToggle(500);
            // $('.paragUp1').not($(this).next()).slideUp(500); 
        })

      }, [])

  return (
    <>

    <section className={`${styles.colorAnimatedBG} pt-5`}>

      <div className="container">

           <div className="row">
       <h2 className='text-center mt-5 mb-3 fw-bold text-white'>FAQ
                  <div className="longline mt-1"></div>
                  <div className="shortline mt-1"></div>
                  
       </h2>

       {/* <div className="col-md-12 my-5">
           <h3 className={`${styles.movingHeader} text-center headerAnimated`}>FAQ</h3>
           <div className={`${styles.paragHover} text-white paragUp1`}>
              <div className='border p-2'> 
              <h5>Q1- I have internet in the pharmacy, but sometimes I cannot work on the program what is the reason?</h5>
              <p>Please note if the pharmacy is the branch of one of the groups was the section observer cameras, the camera pulls a very large percentage of the speed of the Internet so would prefer separate the software from the private online cameras online.</p>
              </div>
              <div  className='border p-2'> 
              <h5>Q2- Should I have to download Java on the PC version?</h5>
              <p>No, not necessary for downloading a Java program.</p>
              </div>
              <div  className='border p-2'> 
              <h5>Q3- I forgot the password for the program what is the solution?</h5>
              <p>If you registed your E-mail ,you can reset password from login page,or You can contact figures technical support.</p>
              </div>
              <div  className='border p-2'> 
              <h5>Q4- Should enter the user name and password each time I work a prescription?</h5>
              <p>No, you can save your username and password at your browser.</p>
              </div>
              <div  className='border p-2'> 
              <h5>Q5- What needs to spend a prescription for the patient?</h5>
              <p>requires a medical card applicable to the patient itself in the case of daily prescription required the presence of the prescription on one of the company's models either in the case of monthly treatment you'll need to be patient a drug already registered by the medical department of the company.</p>
              </div>
              <div  className='border p-2'> 
              <h5>Q6- Can a daily or monthly exchange treatment without a medical card?</h5>
              <p>It cannot be dismissed any medicine, but the medical card in force.</p>
              </div>
              <div  className='border p-2'> 
              <h5>Q7- What is the use date of prescription box located on the home page of the program?</h5>
              <p>where he cannot be dismissed drug Bruchth older than seven days in the case if it has been more than seven days required the approval of the prescription by the company.</p>
              </div>
              <div  className='border p-2'> 
              <h5>Q8- Should the introduction of specialized doctor in the case of daily prescription? And that's how I know? What interest?</h5>
              <p>Yes, you must insert a diagnosis of prescription - because some diagnoses were not covered and therefore when no diagnosis in writing, the patient could have distracted the drug is owed him and therefore could be deducted to the pharmacist to get the patient him without his face right as some drugs are not covered, but in some diagnoses and thus in the absence of the diagnosis in writing, the medicine will refuse to spend it in spite of his patient - at least the maturity needed one either diagnosed only if the prescription contains more than one diagnosis will need all the diagnoses recorded.</p>
              </div>
              <div  className='border p-2'> 
              <h5>Q10- Introduced a medical card number of the patient appeared proportion of carrying on the program differs from the ratio of endurance code on medical card?</h5>
              <p>The commitment by endurance code on medical card</p>
              </div>
              <div  className='border p-2'> 
              <h5>Q11- No prescription by a daily dose or duration of treatment what do you do?</h5>
              <p>At the same pharmaceutical formulations medicines (syrup), it is found two ways to exchange either by specifying a daily dose and duration of treatment is calculated program the number of units and there is another way if you select a doctor in the prescription number of units are writing the number of units in the box (Read Edit) either in the case of the rest of the formulas Pharmaceutical is spent according to the daily dose or the number of days is spent 1 × 1 and hence the program payout lower unit or on the patient back to the doctor to determine the dose and duration of treatment.</p>
              </div>
              <div  className='border p-2'> 
              <h5>Q12- What is the difference between the duration and the Total duration in the registration of medicines screen?</h5>
              <p>Duration or Duration is the time that will be spent on the basis of quantity or Total duration it is full time that enough medication to the patient Example: if the patient is paid weekly injections for a month, the pharmacist will be written in the Dose number one box in 4 duration writes either in total duration will be 28 for the four injection enough patient for 28 days. Another example: if the patient spent 3 cans drinking enough medicine for 15 days, he Dose = 1, duration = 3, total duration = 15.</p>
              </div>
              <div  className='border p-2'> 
              <h5>Q13- What time period during which the work can edit or delete to daily prescription dispensed branch?</h5>
              <p>The work can delete or modify daily prescription provided that the amendment or deletion in the same day as the payment of any prescription before 7 days of exchange prescription.</p>
              </div>
              <div  className='border p-2'> 
              <h5>Q14- The patient carries a daily prescription treatment or monthly and carries the approval of not carrying endurance ratio what do I do?</h5>
              <p>Appo pressure on the button next to the proportion of endurance in the exchange screen, but you must make sure there is approval of the seal intolerance endurance proportion of the patient, but will be deducted from pharmacy.</p>
              </div>
              <div  className='border p-2'> 
              <h5>Q15- The patient carries a daily prescription treatment or monthly and holds approval not to apply the maximum prescription what do I do?</h5>
              <p>Appo pressure on the button next to the excess value of the insurance limit in exchange screen, but you must make sure there is stamp approval of the lack of restriction to limit the insurance for the patient and not to be deducted from pharmacy.</p>
              </div>
              <div  className='border p-2'> 
              <h5>Q16- In the monthly treatment alternative you can exchange for the drug is not available?</h5>
              <p>Yes, you can substitute the exchange in the monthly treatment in the case of non-availability of medicine original condition that is a variant of the same drug-original Pharmaceutical Group and at the same its own pharmaceutical formula and the price of the alternative minimum dosage of origin or group increases the value does not exceed ten pounds of the package.</p>
              </div>
              <div  className='border p-2'> 
              <h5>Q17- Is it possible for all users of the program to see the value of the private pharmacy claim?</h5>
              <p>No, since he is no user name and password to enter the exchange screen, user name, and another password to access the screen reports and therefore not for everyone a right of access to the reports screen, but who has the authority to do so.</p>
              </div>
           </div>
          
       </div> */}

       <div className='d-flex justify-content-center align-items-center my-2 '>
             <div className={`${styles.QEdit} fs-1 me-3`}>
               Q1 
              </div>
             <div className={`${styles.AnswerEdit} `}>
             <div className='fw-bold'> I have internet in the pharmacy, but sometimes I cannot work on the program what is the reason?</div>
              <div>Please note if the pharmacy is the branch of one of the groups was the section observer cameras, the camera pulls a very large percentage of the speed of the Internet so would prefer separate the software from the private online cameras online.</div>
             </div>
       </div>
       <div className='border-bottom w-75 m-auto'></div>
       <div className='d-flex justify-content-start align-items-center  my-2'>
             <div className={`${styles.QEdit} fs-1 me-3`}>
               Q2 
              </div>
             <div className={`${styles.AnswerEdit}`}>
             <div className='fw-bold'>Should I have to download Java on the PC version?</div>
              <div>No, not necessary for downloading a Java program.</div>
             </div>
       </div>
       <div className='border-bottom w-75 m-auto'></div>
       <div className='d-flex justify-content-start align-items-center  my-2'>
             <div className={`${styles.QEdit} fs-1 me-3`}>
               Q3
              </div>
             <div className={`${styles.AnswerEdit}`}>
             <div className='fw-bold'>I forgot the password for the program what is the solution?</div>
              <div>If you registed your E-mail ,you can reset password from login page,or You can contact figures technical support.</div>
             </div>
       </div>
       <div className='border-bottom w-75 m-auto'></div>
       <div className='d-flex justify-content-start align-items-center  my-2'>
             <div className={`${styles.QEdit} fs-1 me-3`}>
               Q4
              </div>
             <div className={`${styles.AnswerEdit}`}>
             <div className='fw-bold'>Should enter the user name and password each time I work a prescription?</div>
              <div>No, you can save your username and password at your browser.</div>
             </div>
       </div>
       <div className='border-bottom w-75 m-auto'></div>
       <div className='d-flex justify-content-start align-items-center  my-2'>
             <div className={`${styles.QEdit} fs-1 me-3`}>
               Q5
              </div>
             <div className={`${styles.AnswerEdit}`}>
             <div className='fw-bold'>What needs to spend a prescription for the patient?</div>
              <div>requires a medical card applicable to the patient itself in the case of daily prescription required the presence of the prescription on one of the company's models either in the case of monthly treatment you'll need to be patient a drug already registered by the medical department of the company.</div>
             </div>
       </div>
       <div className='border-bottom w-75 m-auto'></div>
       <div className='d-flex justify-content-start align-items-center  my-2'>
             <div className={`${styles.QEdit} fs-1 me-3`}>
               Q6
              </div>
             <div className={`${styles.AnswerEdit}`}>
             <div className='fw-bold'>Can a daily or monthly exchange treatment without a medical card?</div>
              <div>It cannot be dismissed any medicine, but the medical card in force.</div>
             </div>
       </div>
       <div className='border-bottom w-75 m-auto'></div>
       <div className='d-flex justify-content-start align-items-center  my-2'>
             <div className={`${styles.QEdit} fs-1 me-3`}>
               Q7
              </div>
             <div className={`${styles.AnswerEdit}`}>
             <div className='fw-bold'> What is the use date of prescription box located on the home page of the program?</div>
              <div>where he cannot be dismissed drug Bruchth older than seven days in the case if it has been more than seven days required the approval of the prescription by the company.</div>
             </div>
       </div>
       <div className='border-bottom w-75 m-auto'></div>
       <div className='d-flex justify-content-start align-items-center  my-2'>
             <div className={`${styles.QEdit} fs-1 me-3`}>
               Q8
              </div>
             <div className={`${styles.AnswerEdit}`}>
             <div className='fw-bold'> Should the introduction of specialized doctor in the case of daily prescription? And that's how I know? What interest?</div>
              <div>Yes, we should be chosen specialization from one of the specialties found a devoted doctor who revealed he has a doctor - cannot see specialization either seal the doctor if the presence or the patient can be the same question about the specialty that detects him - helps allocate the doctor to provide a complete service as some disciplines have special treatment by the benefits enjoyed by the doctor to the patient (eg teeth - women - ...) and therefore not to be devoted doctor deprives the patient of the benefits he deserves or give the patient other advantages do not deserve.</div>
             </div>
       </div>
       <div className='border-bottom w-75 m-auto'></div>
       <div className='d-flex justify-content-start align-items-center  my-2'>
             <div className={`${styles.QEdit} fs-1 me-3`}>
               Q9
              </div>
             <div className={`${styles.AnswerEdit}`}>
             <div className='fw-bold'>  Is it necessary to enter the diagnosis in the case of daily prescription? And why? How many diagnosis minimum required being admitted?</div>
              <div>Yes, you must insert a diagnosis of prescription - because some diagnoses were not covered and therefore when no diagnosis in writing, the patient could have distracted the drug is owed him and therefore could be deducted to the pharmacist to get the patient him without his face right as some drugs are not covered, but in some diagnoses and thus in the absence of the diagnosis in writing, the medicine will refuse to spend it in spite of his patient - at least the maturity needed one either diagnosed only if the prescription contains more than one diagnosis will need all the diagnoses recorded</div>
             </div>
       </div>
       <div className='border-bottom w-75 m-auto'></div>
       <div className='d-flex justify-content-start align-items-center  my-2'>
             <div className={`${styles.QEdit} fs-1 me-3`}>
               Q10
              </div>
             <div className={`${styles.AnswerEdit}`}>
             <div className='fw-bold'> Introduced a medical card number of the patient appeared proportion of carrying on the program differs from the ratio of endurance code on medical card?</div>
              <div>The commitment by endurance code on medical card</div>
             </div>
       </div>
       <div className='border-bottom w-75 m-auto'></div>
       <div className='d-flex justify-content-start align-items-center  my-2'>
             <div className={`${styles.QEdit} fs-1 me-3`}>
               Q11
              </div>
             <div className={`${styles.AnswerEdit}`}>
             <div className='fw-bold'> No prescription by a daily dose or duration of treatment what do you do?</div>
              <div>At the same pharmaceutical formulations medicines (syrup), it is found two ways to exchange either by specifying a daily dose and duration of treatment is calculated program the number of units and there is another way if you select a doctor in the prescription number of units are writing the number of units in the box (Read Edit) either in the case of the rest of the formulas Pharmaceutical is spent according to the daily dose or the number of days is spent 1 × 1 and hence the program payout lower unit or on the patient back to the doctor to determine the dose and duration of treatment.</div>
             </div>
       </div>
       <div className='border-bottom w-75 m-auto'></div>
       <div className='d-flex justify-content-start align-items-center  my-2'>
             <div className={`${styles.QEdit} fs-1 me-3`}>
               Q12
              </div>
             <div className={`${styles.AnswerEdit}`}>
             <div className='fw-bold'>What is the difference between the duration and the Total duration in the registration of medicines screen?</div>
              <div>Duration or Duration is the time that will be spent on the basis of quantity or Total duration it is full time that enough medication to the patient Example: if the patient is paid weekly injections for a month, the pharmacist will be written in the Dose number one box in 4 duration writes either in total duration will be 28 for the four injection enough patient for 28 days. Another example: if the patient spent 3 cans drinking enough medicine for 15 days, he Dose = 1, duration = 3, total duration = 15.</div>
             </div>
       </div>
       <div className='border-bottom w-75 m-auto'></div>
       <div className='d-flex justify-content-start align-items-center  my-2'>
             <div className={`${styles.QEdit} fs-1 me-3`}>
               Q13
              </div>
             <div className={`${styles.AnswerEdit}`}>
             <div className='fw-bold'>What time period during which the work can edit or delete to daily prescription dispensed branch?</div>
              <div>The work can delete or modify daily prescription provided that the amendment or deletion in the same day as the payment of any prescription before 7 days of exchange prescription.</div>
             </div>
       </div>
       <div className='border-bottom w-75 m-auto'></div>
       <div className='d-flex justify-content-start align-items-center  my-2'>
             <div className={`${styles.QEdit} fs-1 me-3`}>
               Q14
              </div>
             <div className={`${styles.AnswerEdit}`}>
             <div className='fw-bold'>The patient carries a daily prescription treatment or monthly and carries the approval of not carrying endurance ratio what do I do?</div>
              <div>Appo pressure on the button next to the proportion of endurance in the exchange screen, but you must make sure there is approval of the seal intolerance endurance proportion of the patient, but will be deducted from pharmacy.</div>
             </div>
       </div>
       <div className='border-bottom w-75 m-auto'></div>
       <div className='d-flex justify-content-start align-items-center  my-2'>
             <div className={`${styles.QEdit} fs-1 me-3`}>
               Q15
              </div>
             <div className={`${styles.AnswerEdit}`}>
             <div className='fw-bold'>The patient carries a daily prescription treatment or monthly and holds approval not to apply the maximum prescription what do I do?</div>
              <div>Appo pressure on the button next to the excess value of the insurance limit in exchange screen, but you must make sure there is stamp approval of the lack of restriction to limit the insurance for the patient and not to be deducted from pharmacy.</div>
             </div>
       </div>
       <div className='border-bottom w-75 m-auto'></div>
       <div className='d-flex justify-content-start align-items-center  my-2'>
             <div className={`${styles.QEdit} fs-1 me-3`}>
               Q16
              </div>
             <div className={`${styles.AnswerEdit}`}>
             <div className='fw-bold'>In the monthly treatment alternative you can exchange for the drug is not available?</div>
              <div >Yes, you can substitute the exchange in the monthly treatment in the case of non-availability of medicine original condition that is a variant of the same drug-original Pharmaceutical Group and at the same its own pharmaceutical formula and the price of the alternative minimum dosage of origin or group increases the value does not exceed ten pounds of the package.</div>
             </div>
       </div> 
       <div className='border-bottom w-75 m-auto'></div>
       <div className='d-flex justify-content-start align-items-center  my-2'>
             <div className={`${styles.QEdit} fs-1 me-3`}>
               Q17
              </div>
             <div className={`${styles.AnswerEdit}`}>
             <div className='fw-bold'>Is it possible for all users of the program to see the value of the private pharmacy claim?</div>
              <div >No, since he is no user name and password to enter the exchange screen, user name, and another password to access the screen reports and therefore not for everyone a right of access to the reports screen, but who has the authority to do so.</div>
             </div>
       </div> 

          </div>


      </div>

    </section>
 
 
    </>
  )
}
 