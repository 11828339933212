import React from 'react'
import styles from '../PageSixFolder/PageSix.module.css'
import { Link } from 'react-router-dom'
import footerImg from '../Media/dnaImgBg.webp'

export default function PageSix() {
  return (
   <>
       <section className={`${styles.footerImg} text-center `} id='profile'>
      <div className='position-relative'>
           <img src={footerImg} alt="" className={`${styles.imgFooterHover} w-100`}/> 
           <div className={`${styles.layer} d-flex align-items-center justify-content-center opacity-75`}>
            <div>
                  <div className='text-white fs-4'> 
                    <Link  className={`${styles.arrowBG}`} to='/aboutUs'>
                        <i className={`${styles.arrowEdit} fa-solid fa-arrow-right rounded-circle`}></i> 
                    </Link>
                  </div> 
                   <h2 className={`${styles.textFooterEdit}`}>About Us</h2>
                    
   
            </div>

            </div> 
      </div>
        
    </section>
   
   </>
  )
}
